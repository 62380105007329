.custom-message__wrapper {
  display: flex;
  flex-direction: column;
  padding: 6px 10px;
  position: relative;

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 2px;
  }

  & > div > div {
    display: flex;
    flex-direction: row;
    padding: 0px 10px;
    position: relative;
  }

  & > div > div[aria-label='Reaction list'] {
    & > ul {
      background: var(--background);
      border-radius: 20px;
      padding: 3px;
    }

    & > ul > li {
      background: transparent;
      box-shadow: none;
      border-radius: none;
      border-block-start: none;
      border-block-end: none;
      border-inline-start: none;
      border-inline-end: none;
      padding: 0px;
      margin-right: 3px;

      & > button {
        background: transparent;
        padding: 0px;
      }
    }
  }

  &--pinned {
    background: var(--white-color);
    border-bottom: .5px solid #cccc;
    box-shadow: var(--box-shadow);
    z-index: 20;

    & p.message-owner {
      color: var(--user-text-color) !important;

      & > *:last-child {
        display: block;
        margin-left: 26px;
      }
    }

    .custom-message__content {
      flex-wrap: wrap;

      & > svg:first-child {
        order: 1;
      }

      & > p:nth-child(2) {
        order: 2;
      }

      & > p:last-child {
        order: 3;
        width: 100%;
        padding-left: 20px;
      }
    }
  }

  & > button {
    background: transparent;
    padding: 0px;
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 20;

    &:hover {
      background: transparent;
    }
  }

  .custom-message__content {
    display: flex;
    align-items: flex-start;
    gap: 4px;

    & > img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-top: 3px;
    }

    .user-icon {
      width: 100%;
      height: 100%;
    }

    .timestamp {
      display: inline;
      white-space: nowrap;
    }

    > .message-owner {
      min-width: fit-content;
      color: var(--user-text-color);
    }
  }

  .custom-message__reaction-list {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 0px;
    padding-top: 0px !important;

    @mixin reaction-badge-layout {
      display: flex;
      align-items: center;
      height: 20px;
      padding-inline: 8px;
    }

    .custom-message__voting-stats {
      @include reaction-badge-layout;
      justify-content: space-evenly;
      gap: 6px;
      width: fit-content;
      max-width: 100px;

      > p {
        display: inline;
      }
    }

    .custom-message__reply-count {
      @include reaction-badge-layout;
    }
  }

  .custom-message__actions-wrapper {
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 25px;
    width: max-content;

    button > svg path {
      fill: var(--foreground);
    }

    button,
    button:hover, button:active, button:focus {
      padding: 0;
      width: 28px !important;
      height: 28px !important;
      height: 15px;
    }

    & > div {
      .str-chat__reaction-selector.str-chat__message-reaction-selector {
        position: relative;

        & > ul {
          background: transparent;
          box-shadow: none;
          border-radius: none;
          border-block-start: none;
          border-block-end: none;
          border-inline-start: none;
          border-inline-end: none;
        }
      }
    }
  }
}

