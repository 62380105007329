.home-container {
    &__no-match {
        grid-row: 5 / 13;
        width: 100%;
        max-width: var(--max-width-container);
        background: var(--background);
        background-image: url("../../assets/images/simbolo-laliga-bg.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: left;
        display: flex;
        justify-content: center;
        align-items: center;

        & > div {
            background: var(--white-color);
            width: 90%;
            height: max-content;
            padding: 75px 30px 10px 30px;
            border-radius: 20px;
            text-align: center;
            position: relative;

            &::before {
                position: absolute;
                content: " ";
                width: 150px;
                height: 150px;
                border-radius: 50%;
                left: 50%;
                right: 50%;
                transform: translateX(-50%);
                top: -60px;
                background: var(--white-color);
                background-image: url("../../assets/images/icon-porteria.svg");
                background-repeat: no-repeat;
                background-size: 50% 50%;
                background-position: center;
            }

            & h4 {
                font-weight: 100;
            }
        }
    }
}