.str-chat__thread {
  position: absolute;
  margin: 0;
  width: var(--sidebar-width);
  max-width: var(--sidebar-width);
  top: 60px;
  border-left: none;

  .str-chat__thread-list {
    padding: 0;

    .str-chat__parent-message-li {
      margin: 0;
      padding: 0;

      border-block-end: none !important;
      box-shadow: var(--box-shadow-poll);
    }

    .custom-message__reaction-list,
    .custom-message__actions-wrapper,
    .custom-message__voting-stats,
    .str-chat__thread-start {
      display: none;
    }
  }
}

.str-chat__thread-container.str-chat__thread.str-chat__thread--virtualized {
  .str-chat__message-list-scroll {
    height: 80% !important;
  }
}

.str-chat__virtual-list-message-wrapper.str-chat__li.str-chat__li--single {
  padding: 0px 15px;
}
