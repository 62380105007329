@use "../mixins";

.str-chat {
  --str-chat__mention-list-user-item-color: var(--foreground);
  --str-chat__mention-list-user-item-at-sign-color: var(--foreground);
  --str-chat__slash-command-color: var(--foreground);
  --str-chat__mention-list-emoji-item-color: var(--foreground);
}

.rta__autocomplete.str-chat__suggestion-list-container {
  border: 3px solid var(--border-color);
  border-radius: 16px;
  color: var(--foreground);

  .str-chat__slash-command-header,
  .str-chat__emoji-item--name,
  .str-chat__user-item--name {
    font-size: .87rem;
  }

  .str-chat__suggestion-item--selected {
    background: var(--button-background-hover);
  }

  .str-chat__slash-command {
    &:hover {
      background: var(--button-background-hover);
    }

    .str-chat__slash-command-description {
      font-size: .75rem;
    }
  }
}

.channel-footer,
.str-chat__message-input {
  background: var(--component-background);
  border-top: 1px solid var(--border-color);

  .channel-footer__top,
  .str-chat__message-textarea-container {
    background: var(--button-background-hover);
    border-radius: 0.5rem;

    &:focus-within {
      background: var(--white-color);
    }

    .str-chat__textarea {
      background: none;
      height: 100%;
      overflow-y: hidden;
      display: flex;
      align-items: center;

      textarea {
        font-size: 1rem;
        background: transparent;
        border: none;
        resize: none;
        color: var(--user-text-color);
        outline: none;
        padding: 10px 0px;

        &::placeholder {
          color: #CB2F2F;
        }

        &:focus {
          &::placeholder {
            color: var(--foreground);
          }
        }
      }
    }

    .emoji-button {
      background: transparent;
      border-radius: var(--str-chat__border-radius-circle);

      &:hover {
        svg {
          fill-opacity: 1;
        }
      }
    }
  }

  .channel-footer__bottom {
    .watcher-count-button {
      @include mixins.default-button;
      transition: all 0.2s;
      font-size: 0.75rem;
    }

    .send-message-button {
      @include mixins.default-button;
      opacity: 1;
      transition: opacity 0.2s;
      border-radius: 50% !important;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: .65rem .35rem .65rem .8rem !important;
      background: var(--white-color) !important;

      path {
        fill: var(--foreground);
      }

      &:disabled {
        background: var(--button-background-hover);
        opacity: 0.5;
      }
    }
  }
}

.typing-indicator {
  .dots {
    .dot {
      background: var(--foreground);
      border-radius: 50%;
      animation: typing-indicator-animation 1s infinite;
      transition: all 1s;
    }

    .dot:nth-of-type(1) {
      animation-delay: 0.2s;
    }
    .dot:nth-of-type(2) {
      animation-delay: 0.4s;
    }
    .dot:nth-of-type(3) {
      animation-delay: 0.6s;
    }

    @keyframes typing-indicator-animation {
      0% {
        transform: translateY(0) scale(1);
      }

      50% {
        transform: translateY(-6px) scale(0.75);
      }

      100% {
        transform: translateY(0) scale(1);
      }
    }
  }

  p {
    color: rgba($color: var(--foreground), $alpha: 0.5);
    font-size: 0.75rem;
  }
}

.str-chat__input--emojipicker {
  button, button:hover {
    background: none;
    // todo: vendor stylesheet sets cursor: default inside a button. does not make sense. remove that rule
    span {
      cursor: unset;
    }
  }

  > section {
    background: var(--background);
    border: 3px solid var(--border-color);
    border-radius: 16px;

    .emoji-mart-category-label {
      background: var(--background);

      span {
        background: none;
        color: var(--foreground);
      }
    }

    .emoji-mart-search input {
      border-color: var(--border-color);
    }

    .emoji-mart-bar {
      border: none;

      .emoji-mart-anchor {
        color: #858585;
      }

      .emoji-mart-anchor-selected, .emoji-mart-anchor:focus, .emoji-mart-anchor:hover {
        color: #464646;
      }
    }
  }
}