.video-container {
  width: 100%;
  max-height: 30rem;

  &.chat-visible {
    max-height: 40rem;
    margin: 0;
    width: 100%;
  }
}
