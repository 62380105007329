// colors
#root {
  --background: #F3F3F3;
  --component-background: #FF4B44;
  --button-background: #FF4B44;
  --button-background-hover: #FFBEBE;

  --foreground: #4A4A4A;

  --user-text-color: #212121;

  --attendees: #00fc92;

  --border-color: #FF4B44;
  --border-color-secondary: #FF4B44;

  --header-height: 60px;
  --sidebar-width: 100%;

  --btn-border-radius: .5rem;

  --input-focused-box-shadow: 0 0 0 2px #FFBEBE;

  --white-color: #fff;

  --home-size-text: 18px;

  --max-width-container: 700px;

  --blue-color: #00D4D6;
  --purple-color: #9B5CFF;

  --chat-background: #F6F6F6;
  --poll-background: #D1D1D1;

  --box-shadow: 0 2px 2px 0 rgba(0,0,0,0.07);
  --box-shadow-poll: 0 3px 10px 0 rgba(0,0,0,0.24);
}



