section.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: var(--sidebar-width);
  max-width: var(--max-width-container);
  height: 100%;
  overflow: hidden;

  & > header {
    background: var(--white-color);
    display: flex;
    flex-direction: column;
    height: 15%;
    border-bottom: 1px solid #cccc;
    padding: 7px 10px;
    position: relative;

    & > *:first-child {
      width: 100%;
      justify-content: flex-end;
      align-items: center;
      min-width: 70px;
      min-height: 20px;
      display: flex;
      position: relative;

      & > svg {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }

      & > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 3px;
        height: 100%;

        h3[data-name='watchers'] {
          color: var(--foreground);
          font-size: 15px;
        }

      }
    }

    & > *:last-child {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }

  .chat-container {
    width: 100%;
    height: 86%;
    position: relative;

    .str-chat__main-panel {
      padding: 0;

      .str-chat__main-panel-inner {
        flex: 1 1;
      }

      .str-chat__list {
        .str-chat__message-list-scroll {
          .str-chat__li {
            position: relative;
            margin: 0;
            padding: 0px 15px;
            min-height: 40px;

            &:hover {
              background: var(--background);
            }
          }

          .str-chat__date-separator {
            display: none;
          }
        }
      }
    }
  }
}

.str-chat__list {
  padding: 0;

  .str-chat__message-list-scroll {
    padding: 0;
  }
}