@use "../mixins";

.home-container {
    max-width: var(--max-width-container);
    width: 100%;
    margin: auto;
    height: 100%;
    display: grid;
    grid-template-rows: repeat(12, 1fr);

    .terms-and-conditions {
        grid-row: 10 / 11;
        width: 100%;
        margin: auto;
        display: flex;
        gap: 20px;
        justify-content: center;

        a, a:visited, a:active {
            text-align: center;
            color: #6e6e6e;
            font-size: 12px;
            transition: all .3s;
            padding: 8px;
            border-radius: 20px;
            text-decoration: none;

            &:hover {
                color: black;
                background-color: #d7d7d7;
            }
        }
    }

    & > header {
        @include mixins.with-background-image('/assets/icons/logo-laliga-livechat.svg');
        width: 50%;
        max-width: 200px;
        margin: auto;
        grid-row: 2 / 5;
        height: 100%;
        background-color: transparent !important;
        border-bottom: none !important;
        background-size: contain;
    }

    & > img {
        grid-row: 12 / 13;
        margin: auto;
    }

    &__intro {
        grid-row: 5 / 10;
        background: var(--white-color);
        border-radius: 20px;
        padding: 1rem;
        justify-content: space-around;
        align-items: center;
        height: 100%;
        width: 93%;
        margin: auto;

        display: grid;
        grid-template-rows: repeat(12, 1fr);

        @media (max-width: 376px) {
            grid-row: 5 / 10;
        }

        @media (max-width: 281px) {
            grid-row: 5 / 10;
        }

        & > header {
            background-color: transparent !important;
            border-bottom: none !important;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            width: 100%;
            height: 100%;
            grid-row: 1 / 7;

            & > img {
                width: 50%;
                height: 100%;
                object-fit: contain;
            }
        }

        & > section {
            grid-row: 7 / 11;
            text-align: center;
            font-size: 1.125rem;
            color: var(--foreground);
            align-self: self-start;

            & > h2 {
                font-weight: 700;
                font-size: inherit;
                margin-bottom: 10px;
            }
            & > p {
                font-weight: 100;
            }

            @media (max-width: 430px) {
                grid-row: 7 / 10;

                & > h2 {
                    margin-bottom: 5px;
                }
                & > h2,
                & > p {
                    font-size: 15px;
                }
            }
        }

        & > footer {
            width: 100%;
            height: 90%;
            grid-row: 11 / 13;
            border-radius: 5px;
            background: var(--button-background);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            & > span {
                text-transform: uppercase;
                color: var(--white-color);
                text-decoration: none;
                padding: 10px 0px;
                font-size: .87rem;
            }
        }

    }
}

.loading-container {
    width: 100%;
    height: 100%;
    grid-row: 1 / 13;
    display: flex;
    align-items: center;
    animation: loading_la_liga 1s infinite ease-in-out;
}

@keyframes loading_la_liga {
    0% {
        transform: scale(0.9);
        opacity: .2;
    }
    50% {
        transform: scale(1.05);
        opacity: 1;
    }
    100% {
        transform: scale(0.9);
        opacity: .2;
    }
}