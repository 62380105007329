.custom-message__wrapper {

  .custom-message__content {
    font-size: 1rem;
    line-height: 13px;

    .timestamp {
      font-size: .69rem;
      color: #858688;
    }

    .message-owner {
      word-break: break-word;

      & > span {
        vertical-align: middle;
      }

      & > span:first-child {
        width: 22px;
        height: 22px;
        display: inline-block;
      }

      & > span:nth-child(2) {
        margin: 0px 5px;
      }


      .moderator__tag {
        position: relative;
        font-size: 10px;
        padding: 1px 5px;
        width: max-content;
        margin-right: 5px;
        border-radius: 20px;
        color: var(--white-color);
        background: var(--blue-color);
      }

      .message {
        color: var(--foreground);
        line-height: 25px;
      }
    }

    > .user__information {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
    }
  }

  .custom-message__reaction-list {
    font-size: .75rem;

    @mixin reaction-badge-theme {
      background: var(--background);
      border: 1px solid var(--border-color);
      border-radius: 0 20px 20px 20px;
    }

    .custom-message__voting-stats {
      @include reaction-badge-theme;
    }

    .custom-message__reply-count {
      @include reaction-badge-theme;
      cursor: pointer;
    }
  }

  .custom-message__actions-wrapper {
    background: var(--background);
    box-shadow: -20px 0px 12px 5px rgba(243,243,243,1);
    -webkit-box-shadow: -20px 0px 12px 5px rgba(243,243,243,1);
    -moz-box-shadow: -20px 0px 12px 5px rgba(243,243,243,1);
    border-radius: 99999px;
    gap: 4px;

    button,
    button:hover, button:active, button:focus {
      background: var(--white-color);
      border-radius: 50%;


      svg:hover path {
        fill-opacity: 1;

      }
    }

    button:disabled,
    button[disabled]{
      opacity: .6;
    }
  }
}

.reply-count {
  position: relative !important;
  padding: 2px 5px;
  background-color: var(--background);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.str-chat__thread-container.str-chat__thread {
  .reply-count {
    display: none;
  }
}