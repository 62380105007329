@use "../mixins";

.channel-header,
.thread-header,
.members-header,
.upgrade-header
{
  background-color: var(--component-background);
  transition: all 0.5s;
  justify-content: center !important;
  padding: 5px 0px;
  height: var(--header-height);
  gap: 7px;

  & > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 14px;

    & > img {
      width: 50px;
      height: 50px;
      object-fit: contain;
      background-color: var(--white-color);
      border-radius: 50%;
    }
  }
  & > h3 {
    color: var(--white-color);
    margin: 0px;
    flex-grow: 1;
    flex-basis: 0px;
    padding: 0px 5px;

    &:first-child {
      text-align: right;
    }
  }

  & > div:last-child {
    flex-direction: row-reverse;
  }
}

.thread-header {
  background-color: #fff;
  box-shadow: var(--box-shadow-poll);
  border-bottom: 1px solid #cccc;

  & > button {
    &:hover {
      background-color: #fff;
    }
  }

  & > h2 {
    & > * {
      vertical-align: middle;
    }

    & span {
      margin-left: 5px;
      color: var(--user-text-color);
      font-size: 14px;
    }
  }

}