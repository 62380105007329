.banner-background {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    backdrop-filter: blur(2px);
}

.banner {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 93%;
    margin: auto;
    background: #fff;
    border-radius: 15px;
    box-shadow: var(--box-shadow-poll);
    z-index: 1000;
    left: 0px;
    right: 0px;

    & > header {
        background-color: transparent;
        border-bottom: none;
        justify-content: space-between;

        & > *:first-child {
            padding-left: 7px;
        }

        & > button {
            background-color: transparent;
        }
    }

    &__body {
        padding: 5px 10px 10px 10px;

        .banner-content {
            padding: 7px 5px;
            display: flex;
            flex-direction: row;
            align-items: self-start;
            gap: 5px;
        }
    }

    @media screen and (max-width: 490px) {
        height: 80%;

        &__body {
            overflow-y: auto;
            height: 85%;
        }
    }
}
