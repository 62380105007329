.progress-bar {
    width: 100%;
    height: 5px;
    background: var(--background);
    border-radius: 10px;

    &__inner {
        height: 100%;
        background: var(--purple-color);
        border-radius: inherit;
    }
    &__active {
        transition: all 0.3s;
    }
}
