.user-information {
  width: max-content;
  height: 35px;
  background: rgb(234, 234, 234);
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 3px 5px 10px;
  gap: 7px;
  position: relative;

  &__options {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 3px;
    cursor: pointer;

    & > h3 {
      font-size: 1rem;
      color: var(--foreground);
    }

    & > svg {
      fill: var(--foreground);
    }

    & > div {
      width: 100%;
      height: 100%;
      max-height: max-content;
      max-width: max-content;
      background: var(--background);
    }
  }

  .dropdown-content {
    display: none;
    position: absolute;
    top: 100%;
    right: 0px;
    background: var(--white-color);
    min-width: 186px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 21;
    height: max-content;
    border-radius: 10px;
    overflow: hidden;

    & > a {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 7px;

      &:hover {
        background: #ddd;
      }
    }
  }

  &__avatar {
    height: 30px;
    width: 30px;
    border-radius: 50%;

    & > div,
    & > div > div {
      width: 100% !important;
      height: 100% !important;
      border-radius: 50%;

      & > img {
        border-radius: 50% !important;
        object-fit: contain !important;
        max-width: none !important;
        width: auto !important;
        height: 100% !important;
      }
    }
  }
}

.active-dropdown .dropdown-content {
  display: block;
}
