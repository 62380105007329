.chat-notification {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 132px;
  right: 0;
  width: var(--sidebar-width);
  height: 100px;
  z-index: 11;
}