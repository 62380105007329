@use "../mixins";

.custom-game-information {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
    padding: 0px 1.5rem;
    text-align: center;
    background: var(--white-color);

    & p,
    & h2 {
        margin: 0px;
        font-size: 1.125rem;
        color: var(--foreground);
    }

    & > h2:first-child {
        font-weight: 400;
    }

    & > header {
        border: none;
        background: transparent;
        gap: 10px;

        & > img {
            width: 80px;
            height: 80px;
            object-fit: contain;
            border: 1px solid #cccc;
            border-radius: 50%;
            padding: 7px;
        }
    }
}

.game-chat-body {
    height: calc(100% - 123px);
    padding: 0px;
    background: var(--white-color);
    display: flex;
    flex-direction: column;

    .popup-confirmation {
        z-index: 1000;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        backdrop-filter: blur(2px);

        &::before {
            position: absolute;
            content: ' ';
            background: var(--foreground);
            opacity: .9;
            width: 100%;
            height: 100%;
        }

        article {
            width: 80%;
            height: max-content;
            border-radius: 20px;
            background: var(--white-color);
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, calc(-50% - 65px));
            overflow: auto;
            padding: 15px 20px;
            text-align: center;

            & > div {
                font-family: 'Work Sans', sans-serif !important;
            }

            header,
            footer {
                background: none;
                border: none;
                display: flex;
            }

            header {
                justify-content: center;
                align-items: center;

                svg {
                    width: max-content;
                    height: 100%;
                }
            }
            footer {
                justify-content: space-around;
                padding-top: 15px;

                button {
                    width: 48%;
                    font-size: .87rem;
                    border: 1px solid var(--button-background);
                    color: var(--button-background);
                    background: var(--white-color);
                    height: max-content;
                    font-weight: 700;
                    transition: all .1s ease-in-out;
                    font-family: 'Work Sans', sans-serif !important;

                    &:hover,
                    &:focus {
                        outline: none;
                        background: var(--button-background);
                        color: var(--white-color);
                    }
                }
            }
        }
    }

    &__pinned {
        position: relative;
        padding: 0px 0px 10px 0px !important;
        .str-chat__main-panel-inner {
            height: 93% !important;
        }
    }
}

.str-chat__message-textarea-emoji-picker-container {
    inset: 0px !important;
    transform: none !important;
    left: 0px;
    right: 0px;
}

.str-chat__message-textarea-with-emoji-picker {
    padding: 2px 10px;
    margin-right: 10px;
    position: relative;
    align-items: center;
}

.str-chat__file-input-container {
    display: none;
}

.str-chat__message-textarea-emoji-picker {
    // background-color: transparent;
    align-self: center;

    & > button {
        background-color: inherit;
        padding: 0px !important;

        svg path {
            fill: var(--button-background);
            opacity: .7;
        }

        &:hover,
        &:focus {
            background-color: inherit;

            svg path {
                opacity: 1;
            }
        }
    }
}

.str-chat__message-textarea-container {
    border: none;
}

.str-chat__send-button {
    @include mixins.default-button;
    opacity: 1;
    transition: opacity 0.2s;
    border-radius: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--white-color) !important;
    padding: .55rem;

    path {
      fill: var(--button-background);
    }

    &:disabled {
      background: var(--button-background-hover);
      opacity: 0.5;
    }
}

.str-chat__message-input-inner {
    gap: 10px;
}

.rta__autocomplete {
    z-index: 10000 !important;
    background: var(--white-color) !important;
    position: fixed !important;
    bottom: 65px !important;
    margin: auto;
    max-width: var(--max-width-container);
    left: 0px;
    right: 0px;
}