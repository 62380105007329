.polls {
    position: relative;
    width: 100%;
}

.polls-container {
    background: var(--white-color);
    color: var(--user-text-color);
    padding: 7px;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: var(--box-shadow-poll);
    width: 93%;
    margin: auto;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 10px;
    z-index: 1;

    &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        font-size: 10px;

        & > div {
            display: inherit;
            flex-direction: row;
            gap: 10px;
            align-items: flex-start;

            & > img {
                margin-top: 2px;
            }

            & > div {
                display: inherit;
                flex-direction: column;

                & > h3 {
                    margin: 0px;
                }

                & > h3:first-child {
                    color: var(--component-background);
                    text-transform: uppercase;
                }

                & > h3:last-child {
                    font-size: 14px;
                }
            }
        }

        & > div[data-name="open-poll"],
        & > div[data-name="close-poll"] {
            cursor: pointer;
        }
    }

    &__body {
        padding: 0px;
        height: max-content;
        max-width: 100%;
        overflow: hidden;

        & > ul {
            padding: 10px 25px;

            & li {
                background: var(--white-color);
                border-radius: 5px;
                margin: 8px 0px;
                cursor: pointer;
                position: relative;
                border: 1px solid #cccc;
                width: max-content;

                .hide__percentage {
                    opacity: 0;
                }

                .show__percentage {
                    opacity: 1;
                }

                .hide__percentage,
                .show__percentage {
                    border-radius: 5px;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: start;
                    left: 0px;

                    .progress__bar {
                        height: 100%;
                        border-top-left-radius: 4px;
                        border-bottom-left-radius: 4px;
                        border-top-right-radius: 0px;
                        border-bottom-right-radius: 0px;
                        z-index: 1;
                        transition: width 0.3s ease-in-out;
                        background: transparent;

                        &-winner {
                            background: var(--blue-color);
                            border-color: var(--blue-color);
                        }
                        &-lose {
                            background: #cccc;
                        }
                    }

                    & > span {
                        right: 15px;
                        z-index: 2;
                        position: absolute;
                    }
                }

                .information {
                    gap: 10px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    padding: 9px 26px 9px 9px;
                    position: relative;
                    z-index: 2;

                    &__active {
                        padding: 9px;
                    }

                    & > div {
                        display: flex;
                        flex-direction: inherit;
                        align-items: center;
                        gap: 7px;

                        & > p {
                            font-weight: bold;
                        }
                    }
                    & > span {
                        font-size: 18px;
                        font-weight: bold;
                    }

                    & img {
                        width: 18px;
                        height: 18px;
                    }
                }
            }
        }
    }

    .polls-body {

        &__open {
            max-height: 500px;
            animation: openPollBody 1s ease-in-out;
        }

        &__close {
            max-height: 0px;
            animation: closePollBody .5s ease-in-out;
        }
    }

    & p {
        color: inherit;
    }

    &__show {
        opacity: 1;
        z-index: 1;
        transition: opacity 7s ease-in-out;
    }

    &__hide {
        opacity: 0;
        z-index: 0;
        animation: hideClosedPoll 5s ease-in-out forwards;
    }
}


.poiner_winner {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--white-color);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -17px;
}

.option__selected {
    border: .5px solid var(--blue-color) !important;
    width: max-content;
    padding-right: 9px;
}

.poll-header__ {

    &open {
        flex-direction: column-reverse;

        & > div {
            width: 100%;
        }

        & div[data-name="close-poll"] {
            transform: rotate(180deg);
        }
    }
    &closed {
        flex-direction: row;

        & > div {
            width: max-content;
        }

        & div[data-name="close-poll"] {
            transform: rotate(0deg);
        }
    }
}

.poll-open {
    z-index: 1;
    &__show {
        opacity: 1;
        animation: showPoll .5s ease-in-out;
    }

    &__hide {
        opacity: 0;
        animation: hidePoll .9s ease-in-out;
    }
}

@keyframes showPoll {
    0% {
        opacity: 0;
        z-index: 0;
    }

    100% {
        opacity: 1;
        z-index: 1;
    }
}

@keyframes hidePoll {
    0% {
        opacity: 1;
        z-index: 1;
    }

    100% {
        opacity: 0;
        z-index: 0;
    }
}

@keyframes hideClosedPoll {
    0% {
        opacity: 1;
        z-index: 1;
        margin-top: 0px;
    }

    50% {
        opacity: 0;
        z-index: 0;
        margin-top: 0px;
    }

    100% {
        opacity: 0;
        z-index: 0;
        margin-top: -45px;
    }
}

@keyframes openPollBody {
    0% {
        max-height: 0px;
    }

    50% {
        max-height: 0px;
    }

    100% {
        max-height: 500px;
    }
}

@keyframes closePollBody {
    0% {
        max-height: 500px;
    }

    50% {
        max-height: 0px;
    }

    100% {
        max-height: 0px;
    }
}
