header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: var(--header-height);
  padding-inline: 0.5rem;

  .stream-details {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex: 1;
    min-width: 0;

    svg.live-icon {
      height: 40px;
      width: 41px;
      min-width: 41px;
    }

    .info {
      flex: 1;
      min-width: 0;

      h2 {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin:0 0 0.3rem 0;
        line-height: 18px;
      }

      .game-details {
        display: flex;
        gap: 1rem;
        line-height: 16px;

        div {
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }
      }
    }
  }

  .stream-involvement {
    display: grid;
    grid-template-columns: repeat(3,1fr);

    .stream-involvement__stat {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding-inline: 0.5rem;
    }

    p {
      @media (max-width: 1100px) {
        display: none;
      }
    }

    @media (max-width: 950px) {
      display: none;
    }
  }

  .chat-opener {
    display: flex;
    align-items: center;
    height: 100%;
    width: fit-content;
    padding-left: 0.5rem;

    &.hide {
      display: none;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.625rem;

      svg {
        height: 26px;
        width: 26px;
      }
    }
  }
}

