@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;700&display=swap');

@layer default-chat-sdk {
  @import '~stream-chat-react/dist/scss/v2/index.scss';
}

@import "general";
@import "layout";
@import "variables";

@import "Attachment";
@import "ChatUpgrades";
@import "GamingChat";
@import "GamingChatHeader";
@import "GamingChatNotification";
@import "GamingFooter";
@import "GamingHeader";
@import "GamingMessage";
@import "GamingMessageInput";
@import "GamingParticipants";
@import "GamingThread";
@import "GamingVideo";
@import "Home";
@import "User";
@import "GameChatInner";
@import "NoMatch";
@import "ProgressBar";
@import "Poll";
@import "Banner";