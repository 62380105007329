html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  height: 100%;
}

main {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  background-color: var(--background);
}

.video-stream {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  min-width: 0;
  justify-content: space-between;
  position: relative;
  transition: all 1s;

  &.show-members {
    width: 34rem;
  }
}
